import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { PageTemplate } from './page'
import { add } from 'cart-localstorage'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import SiteMetadata from '../components/SiteMetadata'
import Button from '../components/shortcodes/Button'
import PageContent from '../components/shortcodes/PageContent'
import ProductPageStyles from '../styles/ProductPage.module.sass'

/**
 * Create Product Template Component
 */
const Product = ({ data }) => {
  const { seaiGrantAmount, maxProductQuantity } = SiteMetadata()

  const [qty, setQty] = useState(1)
  const [tabsActivated, setTabsActivated] = useState(false)
  const [purchasePrice, setPurchasePrice] = useState(data.mdx.frontmatter.priceWithoutGrant)

  // Pre-select default options
  let _options = {}

  data.mdx.frontmatter.options && Object.entries(data.mdx.frontmatter.options).forEach(([i, option]) => {
    _options[ option["text"] ] = {
      "name": option["options"][0]["name"],
      "price": option["options"][0]["price"]
    }
  })

  const [options, setOptions] = useState(_options)

  function addToOrderAction(price, quantity) {
    // Construct title
    let title = data.mdx.frontmatter.title + ' - '

    Object.entries(options).forEach(option => {
      title += option[0] + ': '
      title += option[1]["name"]

      if (option[1]["price"] > 0) {
        title += ' (+€' + option[1]["price"] + ')'
      }

      title += ', '
    })

    title += ' Including Standard Installation'

    add( { id: title, name: title, price: price }, quantity )

    // Go to checkout page
    // TODO: Research using Redux for this purpose in the future
    window.location.href = '/checkout'
  }

  // Increase/Decrease quantity using buttons
  function changeQty(operation) {
    // Prevent decreasing to 0 or increasing above maxProductQuantity
    if (
      (qty <= 1 && operation === 'decrease') ||
      (qty >= maxProductQuantity && operation === 'increase')
    ) {
      return false
    }

    // Clone and change quantity
    let _qty = qty

    if (operation === 'decrease') {
      _qty = ~~_qty - 1
    } else {
      _qty = ~~_qty + 1
    }

    // Replace quantity state with new quantity
    setQty(_qty)
  }

  // Increase/Decrease quantity using number input
  function inputQty(input) {
    let _qty = qty

    if (input === '' || (~~input > 0 && ~~input <= maxProductQuantity)) {
      _qty = ~~input
    }
    else if (input < 1) {
      _qty = 1
    }
    else if (input > maxProductQuantity) {
      _qty = maxProductQuantity
    }

    setQty(_qty)
  }

  // Handle changing product options
  function changeOptions(option, name, price) {
    let _options = options

    _options[option] = {
      "name": name,
      "price": price
    }

    setOptions({..._options})

    // Handle price change
    let optionsCost = 0

    Object.entries(options).forEach(option => {
      optionsCost = ~~optionsCost + ~~option[1]["price"]
    })

    setPurchasePrice(~~data.mdx.frontmatter.priceWithoutGrant + ~~optionsCost)
  }

  return (
    <PageTemplate
      image={data.mdx.fields.imageWide}
      imageAlt={data.mdx.frontmatter.title}
      pageClass={`${data.mdx.frontmatter.pageClass} ${ProductPageStyles.page || ''} hide-cart`}
      pageIdentifier={data.mdx.fields.pageIdentifier}
      showCta={false}
      showContactForm={false}
      title={data.mdx.frontmatter.title}
    >
      <span className={ProductPageStyles.label || ''}>Standard Installation Included</span>

      {/* Highlights */}

      <ul className={ProductPageStyles.highlights || ''}>
        {data.mdx.frontmatter.highlights.map(highlight => {
          return (
            <li key={highlight.icon + highlight.text}>
              <i className={`${ProductPageStyles.icon || ''} ${highlight.icon || ''}`}></i>
              <span>{highlight.text}</span>
            </li>
          )
        })}
      </ul>

      {/* Information Tabs */}

      <Tabs 
        className={`${ProductPageStyles.informationTabs || ''} ${tabsActivated ? 'is-active' : ''}`}
        defaultIndex={-1 /* No tab is selected by default */}
        onSelect={() => setTabsActivated(true) }
      >
        <TabList>
          <Tab className={ProductPageStyles.informationTabSpecifications || ''}>Specifications</Tab>
          <Tab className={ProductPageStyles.informationTabCompatibleCars || ''}>Compatible Cars</Tab>
          <Tab className={ProductPageStyles.informationTabGrant || ''}><i className="icon-seai">SEAI - Sustainable Energy Authority of Ireland</i> Grant Info</Tab>
        </TabList>

        <TabPanel>
          { /* Specifications Tab */ }

          <div className="container is-narrow">
            <table className={`table is-striped ${ProductPageStyles.table || ''}`}>
              <tbody>
                {data.mdx.frontmatter.specifications.map(item => {
                  return (
                    <tr key={item.name}>
                      <td><b>{item.name}</b></td>
                      <td>{item.value}</td>
                    </tr>
                  )
                })}
                <tr>
                  <td><b>Specification Sheet</b></td>
                  <td>
                    <Button class="button is-primary is-small" external={true} text="Download Spec Sheet" url={data.mdx.frontmatter.specificationSheet} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </TabPanel>

        <TabPanel>
          { /* Compatible Cars Tab */ }

          <div className="container is-narrow">
            <p><b className="has-text-link">ALL</b> our chargers are compatible with <b className="has-text-link">ALL</b> electric and hybrid vehicles that support Mode 3 charging with a Type 2 cable. This is standard for Ireland and UK bought vehicles.</p>
            <p>If you have any questions, please contact us.</p>
            <Button to="/ev-charger-compatibility" />
          </div>
        </TabPanel>

        <TabPanel>
          { /* Grant Tab */ }

          <div className="container is-narrow has-text-left">
            <PageContent slug="ev-charger-grants" />
          </div>
        </TabPanel>
      </Tabs>

      {/* Product description */}
      {!tabsActivated && <div className={`container content is-narrow ${ProductPageStyles.productDescription || ''}`}>
        {(() => {
          // Otherwise return the MDX Rendered body
          if (data.mdx.body && data.mdx.body.length) {
            return <MDXRenderer>{data.mdx.body}</MDXRenderer>
          } else {
            return false
          }
        })()}
      </div>}

      {/* Options */}

      {data.mdx.frontmatter.options && <div className={ProductPageStyles.optionsHolder || ''}>
        {data.mdx.frontmatter.options.map((option) => {

          return (
            <div
              key={JSON.stringify(option)}
              className={ProductPageStyles.option || ''}
            >
              <strong>Choose {option.text}:</strong>
              {option.options.map((value) => {
                return (
                  <div // eslint-disable-line
                    key={value.name}
                    className={
                      options[option.text]["name"] === value.name
                        ? 'selected'
                        : ''
                    }
                    onClick={() => changeOptions(option.text, value.name, value.price)}
                  >
                    <span
                      className={`${ProductPageStyles.optionValue || ''}`}
                    >
                      <em>{value.name}</em>
                      <small>
                        {
                          value.price > 0
                            ? ("+€" + value.price)
                            : "Included"
                        }
                      </small>
                    </span>
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>}

      {/* Pricing */}

      <div className={ProductPageStyles.prices || ''}>
        <div className={ProductPageStyles.priceSection || ''}>
          <em>Supply &amp; Installation: </em>
          <i>&euro;</i>
          <span>{purchasePrice}</span>
          {/* <small>incl. VAT</small> */}
        </div>
        <div className={`${ProductPageStyles.priceSection || ''} ${ProductPageStyles.afterGrant || ''}`}>
          <em>After Grant: </em>
          <i>&euro;</i>
          <span>{data.mdx.frontmatter.priceWithGrant}</span>
          {/* <small>incl. VAT</small> */}
        </div>
        <div className={ProductPageStyles.priceSeaiNotice || ''}>
          We issue all the paperwork needed for the <Link to="/ev-charger-grants">&euro;{seaiGrantAmount} <i className="icon-seai">SEAI - Sustainable Energy Authority of Ireland</i> Grant</Link>
        </div>
      </div>

      {/* Get a Quote */}

      <div className={ProductPageStyles.addToOrder}>
        <Link
          className={`button is-primary ${ProductPageStyles.addToOrderButton || ''}`}
          to="/contact"
        >
          Get a Quote Today!
        </Link>

        <Helmet>
          <title>{data.mdx.frontmatter.seoTitle || data.mdx.frontmatter.title + ' | EV Chargers Ireland'}</title>
        </Helmet>

        { data.mdx.frontmatter.schema ?
          <Helmet>
            <script type="application/ld+json">
              {data.mdx.frontmatter.schema}
            </script>
          </Helmet>
          : null 
        }
      </div>
    </PageTemplate>
  )
}

export default Product

export const post = graphql`
  query($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      fields {
        imageWide {
          childImageSharp {
            fluid(maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pageIdentifier
        slug
      }
      frontmatter {
        highlights {
          icon
          text
        }
        priceWithGrant
        priceWithoutGrant
        schema
        specifications {
          name
          value
        }
        specificationSheet
        shortTitle
        title
        seoTitle
        }
    }
  }
`